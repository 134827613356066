<template>
  <RegistrationLayout>
    <template v-slot:header>
      <HMButton
        color="white"
        rounded="full"
        size="x-small"
        fontWeight="semibold"
        textSize="small"
        padding="tight"
        @click="back"
      >
        <span>
          <Icon iconClass="fas fa-chevron-left" /> {{ $t('common.back_btn') }}
        </span>
      </HMButton>
      <div />
    </template>
    <div class="md:w-435 md:mx-auto">
      <Title class="text-center">{{
        $t('account_recovery.forgot_password.forgot_password')
      }}</Title>
      <Form :validator="$v.model" :error="error">
        <FormInput
          v-model="model.firstName"
          :label="$t('account_recovery.forgot_password.first_name')"
          name="firstName"
          :placeholder="$t('account_recovery.forgot_password.first_name')"
        />

        <FormInput
          v-model="model.lastName"
          class="mt-16"
          :label="$t('account_recovery.forgot_password.last_name')"
          name="lastName"
          :placeholder="$t('account_recovery.forgot_password.last_name')"
        />

        <FormInput
          v-model="model.memberNumber"
          class="mt-16"
          :label="$t('account_recovery.forgot_password.member_number')"
          name="memberNumber"
          placeholder="XXXXXXXXXXXX"
        />

        <FormDatePicker
          v-model="model.birthday"
          class="mt-16"
          :label="$t('account_recovery.forgot_password.birthday')"
          name="birthday"
          :maxDate="MAX_MINOR_BIRTH_DATE"
        />
      </Form>
      <Button
        class="w-full"
        :class="{ 'mt-10': formHasError, 'mt-25': !formHasError }"
        :busy="isFetching"
        @click="handleSubmit"
      >
        {{ $t('account_recovery.forgot_password.submit_btn') }}
      </Button>
    </div>
  </RegistrationLayout>
</template>

<script>
import RegistrationLayout from '@components/layouts/RegistrationLayout'
import * as FormComponents from '../../common/forms'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import format from 'date-fns/format'
import { MAX_MINOR_BIRTH_DATE } from '@platform-shared/constants'

export default {
  components: { RegistrationLayout, ...FormComponents },
  data: () => ({
    model: {
      firstName: '',
      lastName: '',
      memberNumber: '',
      birthday: null,
    },
    error: null,
    isFetching: false,
  }),
  validations: {
    model: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      memberNumber: {
        required,
      },
      birthday: {
        required,
      },
    },
  },
  created() {
    this.MAX_MINOR_BIRTH_DATE = MAX_MINOR_BIRTH_DATE
  },
  methods: {
    ...mapActions('registration', ['checkMemberInformation', 'resetPassword']),
    back() {
      this.$router.back()
    },
    onSuccess() {
      this.$router.replace('/')
    },
    fixApostrophes(name) {
      return name
        .replace(/[\u2018\u2019]/g, "'")
        .replace(/[\u201C\u201D]/g, '"')
    },
    async handleSubmit() {
      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return

      try {
        const checkResult = await this.checkMemberInformation({
          firstName: this.fixApostrophes(this.model.firstName),
          lastName: this.fixApostrophes(this.model.lastName),
          birthday: format(this.model.birthday, 'yyyy-MM-dd'),
          memberNumber: this.model.memberNumber,
        })
        if (checkResult.data.registered) {
          const data = {
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            clientMemberId: this.model.memberNumber,
            dob: format(this.model.birthday, 'yyyy-MM-dd'),
          }
          await this.resetPassword(data)
          this.$router.push({ name: 'email-sent', params: { data: data } })
        }
      } catch (error) {
        const { message } = error.json
        this.error = message
      }
    },
  },
  computed: {
    formHasError() {
      return !!this.error
    },
  },
}
</script>
